import Loader from 'components/Loader'
import { Box } from 'components/MUI'

import { useLoadParams } from './hooks'
import { TakenLoan } from './TakenLoad'
import { TakingLoan } from './TakingLoan'

const XUsdLoan = () => {
  const { loading, totalDebt } = useLoadParams()

  if (loading) {
    return (
      <Box justifyItems="center" alignItems="center">
        <Loader />
      </Box>
    )
  }

  if (totalDebt && totalDebt.isZero()) {
    return <TakingLoan />
  }

  return <TakenLoan />
}

export default XUsdLoan
