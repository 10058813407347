import walletSvg from 'assets/icons/wallet.svg'
import AppMenu from 'components/AppMenu/AppMenu'
import { ApproveCheckerRepay, ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { AmountInputWithMax } from 'components/blocks/AmountInput/AmountInput'
import { TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { ButtonDark } from 'components/Button'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { TransactionInfo } from 'components/TransactionInfo/TransactionInfo'
import { TxStatusView } from 'components/TxStatusView/TxStatusView'
import { BigNumber } from 'ethers'
import { useChainBalance } from 'hooks/useChainBalance'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useMemo, useState } from 'react'
import { BN_1E18, ZERO } from 'utils/isZero'

import {
  useAddCollateral,
  useLoadParams,
  useOverpaidXfi,
  useRemainingXUsd,
  useRePayLoan,
  useTakeAdditionalLoan,
  useWithdrawCollateral,
} from './hooks'
import { LoanPositionInfo } from './LoanPositionInfo/LoanPositionInfo'

const OPTIONS = [
  {
    id: 'add',
    label: 'Add collateral',
  },
  {
    id: 'withdraw',
    label: 'Withdraw collateral',
  },
  {
    id: 'repay',
    label: 'Repay loan',
  },
  {
    id: 'take',
    label: 'Take additional loan',
  },
]

export const ManageLoan = () => {
  const [tab, setTab] = useLocalStorage('MANAGE_LOAN', OPTIONS[1])

  return (
    <>
      <AppMenu options={OPTIONS} selected={tab} onChange={setTab} />

      {tab.id === OPTIONS[0].id && <AddCollateral />}
      {tab.id === OPTIONS[1].id && <WithdrawCollateral />}
      {tab.id === OPTIONS[2].id && <RepayLoan />}
      {tab.id === OPTIONS[3].id && <TakeAdditionalLoan />}

      <LoanPositionInfo showXUsdLeft={tab.id === OPTIONS[3].id} />
    </>
  )
}

const RepayLoan = () => {
  const { totalDebt } = useLoadParams()

  const [amount, setAmount] = useState<BigNumber | undefined>(ZERO)
  const noValue = !amount || amount?.isZero()

  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const { pending, action, isError, txInfo, calledWallet } = useRePayLoan(amount, setPendingTx)

  const maxAmount = useMemo(() => {
    return totalDebt.add(BN_1E18.div(10)) // Add 10 cents to pay extra fees during tx execution
  }, [totalDebt])

  return (
    <>
      {pendingTx ? (
        <TxStatusView
          amountIn={amount}
          amountOut={amount}
          isLoading={pending}
          processLabel="Pending"
          completedLabel="Returned!"
          color="dark"
          hash={pendingTx}
          token={TokenSymbol.xUsd}
        >
          <ButtonDark onClick={() => setPendingTx('')}>Manage Position</ButtonDark>
        </TxStatusView>
      ) : (
        <>
          <AmountInputWithMax
            inputValue={amount}
            setInputValue={(v) => v && setAmount(v)}
            decimals={18}
            max={maxAmount}
            rightToken={{
              symbol: TokenSymbol.xUsd,
            }}
            walletIcon={walletSvg}
            balance={totalDebt}
          />

          <TransactionInfo info={txInfo} />

          <ApproveCheckerRepay border={amount || ZERO}>
            <ConfirmInWalletBlock calledWallet={calledWallet}>
              {noValue ? (
                <ButtonDark disabled={noValue}>Enter an amount</ButtonDark>
              ) : (
                <ButtonDark onClick={action} disabled={isError}>
                  <FormActionBtn pending={pending} txInfo={txInfo} labelActive="Repay loan" labelInProgress="Pending" />
                </ButtonDark>
              )}
            </ConfirmInWalletBlock>
          </ApproveCheckerRepay>
        </>
      )}
    </>
  )
}

const TakeAdditionalLoan = () => {
  const { remaining, loading } = useRemainingXUsd()

  const [amount, setAmount] = useState<BigNumber | undefined>(ZERO)
  const noValue = !amount || amount?.isZero()

  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const { pending, action, isError, txInfo, calledWallet } = useTakeAdditionalLoan(amount, setPendingTx)

  return (
    <>
      {pendingTx ? (
        <TxStatusView
          amountIn={amount}
          amountOut={amount}
          isLoading={pending}
          processLabel="Pending"
          completedLabel="Taken!"
          color="dark"
          hash={pendingTx}
          token={TokenSymbol.xUsd}
        >
          <ButtonDark onClick={() => setPendingTx('')}>Manage Position</ButtonDark>
        </TxStatusView>
      ) : (
        <>
          <AmountInputWithMax
            inputValue={amount}
            setInputValue={(v) => v && setAmount(v)}
            decimals={18}
            max={remaining}
            rightToken={{
              symbol: TokenSymbol.xUsd,
            }}
            walletIcon={walletSvg}
            balance={remaining}
          />

          <TransactionInfo info={txInfo} />

          <ApproveCheckerRepay border={amount || ZERO}>
            <ConfirmInWalletBlock calledWallet={calledWallet}>
              {noValue ? (
                <ButtonDark disabled={noValue}>Enter an amount</ButtonDark>
              ) : (
                <ButtonDark onClick={action} disabled={isError}>
                  <FormActionBtn
                    pending={pending || loading}
                    txInfo={txInfo}
                    labelActive="Take loan"
                    labelInProgress="Taking loan"
                  />
                </ButtonDark>
              )}
            </ConfirmInWalletBlock>
          </ApproveCheckerRepay>
        </>
      )}
    </>
  )
}

const AddCollateral = () => {
  const xfiBalance = useChainBalance()

  const [amount, setAmount] = useState<BigNumber | undefined>(ZERO)
  const noValue = !amount || amount?.isZero()

  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const { pending, action, isError, txInfo, calledWallet } = useAddCollateral(amount, setPendingTx)

  return (
    <>
      {pendingTx ? (
        <TxStatusView
          amountIn={amount}
          amountOut={amount}
          isLoading={pending}
          processLabel="Pending"
          completedLabel="Added!"
          color="dark"
          hash={pendingTx}
          token={TokenSymbol.xfi}
        >
          <ButtonDark onClick={() => setPendingTx('')}>Manage Position</ButtonDark>
        </TxStatusView>
      ) : (
        <>
          <AmountInputWithMax
            inputValue={amount}
            setInputValue={(v) => v && setAmount(v)}
            decimals={18}
            max={xfiBalance}
            rightToken={{
              symbol: TokenSymbol.xfi,
            }}
            walletIcon={walletSvg}
            balance={xfiBalance}
          />

          <TransactionInfo info={txInfo} />

          <ConfirmInWalletBlock calledWallet={calledWallet}>
            {noValue ? (
              <ButtonDark disabled={noValue}>Enter an amount</ButtonDark>
            ) : (
              <ButtonDark onClick={action} disabled={isError}>
                <FormActionBtn pending={pending} txInfo={txInfo} labelActive="Add" labelInProgress="Adding" />
              </ButtonDark>
            )}
          </ConfirmInWalletBlock>
        </>
      )}
    </>
  )
}

const WithdrawCollateral = () => {
  const { loading, remaining: xfiBalance } = useOverpaidXfi()

  const [amount, setAmount] = useState<BigNumber | undefined>(ZERO)
  const noValue = !amount || amount?.isZero()

  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const { pending, action, isError, txInfo, calledWallet } = useWithdrawCollateral(amount, setPendingTx)

  const maxOrZero = useMemo(() => (xfiBalance.gt(ZERO) ? xfiBalance : ZERO), [xfiBalance])

  return (
    <>
      {pendingTx ? (
        <TxStatusView
          amountIn={amount}
          amountOut={amount}
          isLoading={pending}
          processLabel="Pending"
          completedLabel="Withdrawn!"
          color="dark"
          hash={pendingTx}
          token={TokenSymbol.xfi}
        >
          <ButtonDark onClick={() => setPendingTx('')}>Manage Position</ButtonDark>
        </TxStatusView>
      ) : (
        <>
          <AmountInputWithMax
            inputValue={amount}
            setInputValue={(v) => v && setAmount(v)}
            decimals={18}
            max={maxOrZero}
            rightToken={{
              symbol: TokenSymbol.xfi,
            }}
            walletIcon={walletSvg}
            balance={maxOrZero}
          />

          <TransactionInfo info={txInfo} />

          <ConfirmInWalletBlock calledWallet={calledWallet}>
            {noValue ? (
              <ButtonDark disabled={noValue}>Enter an amount</ButtonDark>
            ) : (
              <ButtonDark onClick={action} disabled={isError}>
                <FormActionBtn
                  pending={pending || loading}
                  txInfo={txInfo}
                  labelActive="Withdraw"
                  labelInProgress="Withdrawing"
                />
              </ButtonDark>
            )}
          </ConfirmInWalletBlock>
        </>
      )}
    </>
  )
}
