import rewardsIcon from 'assets/images/menu/rewards.svg'
import { TokenSmallBadgeVariant } from 'components/badges/TokenSmallBadge/TokenSmallBadge'
import { TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { ButtonOrange } from 'components/Button'
import { FormHeader } from 'components/FormHeader/FormHeader'
import { ITxTemplateInfo } from 'components/TransactionInfo/TransactionInfo'
import { TxStatusView } from 'components/TxStatusView/TxStatusView'
import { BigNumber } from 'ethers'
import { ReactNode } from 'react'
import { ThemeColors } from 'theme/styled'
import { ZERO } from 'utils/isZero'

export const RewardsLpXFIHeader = () => (
  <FormHeader icon={rewardsIcon} label="Rewards" explanation="Claim WETH Rewards from deBridge Comissions" />
)

export const RewardsXfiHeader = () => <FormHeader icon={rewardsIcon} label="Rewards" explanation="Claim XFI Rewards" />

export const PendingRewardsView = ({
  onBack,
  amount = ZERO,
  color,
  hash,
  token = TokenSymbol.weth,
  txInfo,
  header,
}: {
  onBack: () => void
  amount?: BigNumber
  color: ThemeColors
  hash: string
  token: TokenSmallBadgeVariant
  txInfo?: ITxTemplateInfo
  header: ReactNode
}) => {
  return (
    <TxStatusView
      header={header}
      amountIn={amount}
      amountOut={amount}
      color={color}
      hash={hash}
      token={token}
      txInfo={txInfo}
    >
      <ButtonOrange marginTop="16px" onClick={onBack}>
        Claim more {token?.toUpperCase()}
      </ButtonOrange>
    </TxStatusView>
  )
}
