import swapIcon from 'assets/images/menu/swap.svg'
import { TokenSmallBadgeVariant } from 'components/badges/TokenSmallBadge/TokenSmallBadge'
import { IAppToken, TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { ButtonGreenStyle, ButtonMainStyle, ButtonPrimary } from 'components/Button'
import { FormHeader } from 'components/FormHeader/FormHeader'
import { ITxTemplateInfo } from 'components/TransactionInfo/TransactionInfo'
import { TxStatusView } from 'components/TxStatusView/TxStatusView'
import { BigNumber } from 'ethers'
import { ThemeColors } from 'theme/styled'
import { ZERO } from 'utils/isZero'

export const SwapHeader = () => (
  <FormHeader icon={swapIcon} label="Swap" explanation="Swap your tokens between each other!" />
)

export const PendingSwapView = ({
  onBack,
  amount = ZERO,
  color,
  hash,
  txInfo,
  assetIn,
  assetOut,
  amountIn,
  amountOut,
}: {
  onBack: () => void
  amount?: BigNumber
  color: ThemeColors
  hash: string
  txInfo?: ITxTemplateInfo
  assetIn?: IAppToken
  assetOut?: IAppToken
  amountIn?: BigNumber
  amountOut?: BigNumber
}) => {
  return (
    <TxStatusView
      txInfo={txInfo}
      amountIn={amountIn}
      amountOut={amountOut}
      color={color}
      hash={hash}
      assetIn={assetIn}
      assetOut={assetOut}
      processLabel="You are about to swap"
      completedLabel="Now you’ve got"
    >
      <ButtonMainStyle marginTop="16px" width="100%" onClick={onBack}>
        New Swap
      </ButtonMainStyle>
    </TxStatusView>
  )
}

export const PendingAddLiquidityView = ({
  onBack,
  amount = ZERO,
  token = TokenSymbol.xfi,
  color,
  hash,
  txInfo,
  assetIn,
  assetOut,
  amountIn,
  amountOut,
}: {
  onBack: () => void
  amount?: BigNumber
  color: ThemeColors
  hash: string
  token: TokenSmallBadgeVariant
  txInfo?: ITxTemplateInfo
  assetIn?: IAppToken
  assetOut?: IAppToken
  amountIn?: BigNumber
  amountOut?: BigNumber
}) => {
  return (
    <TxStatusView
      txInfo={txInfo}
      amountIn={amountIn}
      amountOut={amountOut}
      color={color}
      hash={hash}
      token={token}
      assetIn={assetIn}
      assetOut={assetOut}
      processLabel="Adding liqudity..."
      completedLabel="Succesfully added!"
    >
      <ButtonGreenStyle marginTop="16px" width="100%" onClick={onBack}>
        New Position
      </ButtonGreenStyle>
    </TxStatusView>
  )
}

export const PendingRemoveLiquidityView = ({
  onBack,
  amount = ZERO,
  token = TokenSymbol.xfi,
  color,
  hash,
  txInfo,
}: {
  onBack: () => void
  amount?: BigNumber
  color: ThemeColors
  hash: string
  token: TokenSmallBadgeVariant
  txInfo?: ITxTemplateInfo
}) => {
  return (
    <TxStatusView
      txInfo={txInfo}
      amountIn={amount}
      color={color}
      hash={hash}
      token={token}
      processLabel="Removing liqudity..."
      completedLabel="Succesfully removed!"
    >
      <ButtonPrimary marginTop="16px" onClick={onBack}>
        Return back
      </ButtonPrimary>
    </TxStatusView>
  )
}
