import { CardCentered } from 'components/Card'
import styled from 'styled-components'

import { SwapHeader } from './PendingView'
import SwapBlock from './SwapBlock'

const PageWrapper = styled.div`
  flex: 1;
  justify-content: center;
  margin: auto;
  width: 100%;

  display: flex;
`

export default function Swap() {
  return (
    <PageWrapper>
      <CardCentered maxWidth="600px" gap="16px">
        <SwapHeader />

        <SwapBlock />
      </CardCentered>
    </PageWrapper>
  )
}
