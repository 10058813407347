import getIcon from 'assets/images/menu/get.svg'
import { FormHeader } from 'components/FormHeader/FormHeader'

export const GetHeader = () => (
  <FormHeader
    icon={getIcon}
    label="Get"
    explanation="Buy XFI from any EVM compatible chains (deBridge) and transfer to CrossFi Chain"
  />
)
