import { ButtonMainStyle } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { useHandleChainSwitch } from 'components/Header/NetworkSelector'
import ConnectionErrorView from 'components/WalletModal/ConnectionErrorView'
import Option from 'components/WalletModal/Option'
import PendingView from 'components/WalletModal/PendingView'
import { OptionGrid, WALLET_VIEWS } from 'components/WalletModal/WalletModal'
import { connections, deprecatedNetworkConnection, networkConnection } from 'connection'
import { ActivationStatus, useActivationState } from 'connection/activate'
import { isSupportedChain } from 'constants/chains'
import { SupportedChainId } from 'constants/chainsinfo'
import { Paths } from 'constants/paths'
import usePrevious from 'hooks/usePrevious'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ApplicationModal } from 'state/application/actions'
import { useModalOpen, useWalletModalToggle } from 'state/application/hooks'
import styled from 'styled-components'
import { TYPE } from 'theme/theme'

import { useActiveWeb3React } from '../../hooks/web3'
import leftSvg from './left-logo.svg'
import rightSvg from './right-logo.svg'

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 120px;
  flex: 1;

  ${({ theme }) => theme.mediaWidth.upToPhone`
    padding: 120px 24px;
  `};
`

const ContentWarningStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  margin: auto;
  justify-content: center;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 65px;

  ${({ theme }) => theme.mediaWidth.upToPhone`
    font-size: 24px;
  `};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 24px 0;
`

const Imgs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`

interface IProps {
  children?: React.ReactNode
}

const useIsBridge = () => {
  const { pathname } = useLocation()

  return useMemo(() => {
    return pathname && pathname.indexOf(Paths.BRIDGE) >= 0
  }, [pathname])
}

export const useWarningFlag = () => {
  const { account, chainId } = useActiveWeb3React()
  const isBridge = useIsBridge()

  if (!chainId) {
    return {
      notSupportedChain: false,
      account,
    }
  }

  if (isBridge) {
    return {
      notSupportedChain: false,
      account,
      lockedForCurrentChain: isBridge && chainId !== SupportedChainId.XFI_TESTNET,
    }
  }

  return {
    notSupportedChain: chainId !== SupportedChainId.XFI_TESTNET,
    account,
  }
}

const ConfirmBlock = styled.div`
  position: fixed;
  bottom: 0;
  box-shadow: 0px -4px 20px 0px rgba(40, 46, 63, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: ${({ theme }) => theme.light};
  width: 100%;
  gap: 25px;
  height: 70px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  ${({ theme }) => theme.mediaWidth.upToPhone`
      flex-direction: column;
      height: auto;
    `};
`

const SwitchBtn = styled(ButtonMainStyle)`
  width: initial;
  border: 1px solid ${({ theme }) => theme.white};

  ${({ theme }) => theme.mediaWidth.upToPhone`
      width: 100%;
    `};
`

export const WrongNetworkBanner = () => {
  const { chainId } = useActiveWeb3React()
  const handleChainSwitch = useHandleChainSwitch()

  const toggleChain = useCallback(() => {
    handleChainSwitch(SupportedChainId.XFI_TESTNET, true)
  }, [handleChainSwitch])

  const isBridge = useIsBridge()

  if (isBridge) {
    return null
  }

  if (chainId !== SupportedChainId.XFI_TESTNET) {
    return (
      <ConfirmBlock>
        <TYPE.mediumHeader>xAPP is working on CrossFi Chain Testnet </TYPE.mediumHeader>

        <SwitchBtn onClick={toggleChain}>Switch Network</SwitchBtn>
      </ConfirmBlock>
    )
  }

  return null
}

export default function WarningWrapper({ children }: IProps): JSX.Element {
  const { account, notSupportedChain } = useWarningFlag()

  if (account) {
    if (notSupportedChain) {
      return <Banner />
    }

    return children as JSX.Element
  }

  return <Banner />
}

const Banner = () => {
  // important that these are destructed from the account-specific web3-react context
  const { chainId, account, connector } = useActiveWeb3React()

  const { activationState } = useActivationState()
  const fallbackProviderEnabled = true
  // Keep the network connector in sync with any active user connector to prevent chain-switching on wallet disconnection.
  useEffect(() => {
    if (chainId && isSupportedChain(chainId) && connector !== networkConnection.connector) {
      if (fallbackProviderEnabled) {
        networkConnection.connector.activate(chainId)
      } else {
        deprecatedNetworkConnection.connector.activate(chainId)
      }
    }
  }, [chainId, connector, fallbackProviderEnabled])

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const [pendingError, setPendingError] = useState<boolean>()

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const toggleWalletModal = useWalletModalToggle()

  const previousAccount = usePrevious(account)

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal()
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen])

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false)
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [walletModalOpen])

  // close modal when a connection is successful
  const connectorPrevious = usePrevious(connector)
  useEffect(() => {
    if (walletModalOpen && connector && connector !== connectorPrevious) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [setWalletView, connector, walletModalOpen, connectorPrevious])

  return (
    <>
      <WrongNetworkBanner />
      <Content>
        <Imgs>
          <img src={leftSvg} alt="left" />
          <img src={rightSvg} alt="right" />
        </Imgs>

        <ContentWarningStyled>
          <Title>Connect wallet</Title>

          <ContentWrapper>
            {walletView === WALLET_VIEWS.PENDING ? (
              <PendingView error={pendingError} reset={() => setWalletView(WALLET_VIEWS.OPTIONS)} />
            ) : (
              <>
                {activationState.status === ActivationStatus.ERROR ? (
                  <ConnectionErrorView />
                ) : (
                  <AutoColumn gap="16px">
                    <OptionGrid>
                      {connections
                        .filter((connection) => connection.shouldDisplay())
                        .map((connection) => (
                          <Option key={connection.getProviderInfo().name} connection={connection} />
                        ))}
                    </OptionGrid>
                  </AutoColumn>
                )}
              </>
            )}
          </ContentWrapper>
        </ContentWarningStyled>
      </Content>
    </>
  )
}
