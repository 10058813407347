import TokenSmallBadge from 'components/badges/TokenSmallBadge/TokenSmallBadge'
import { AutoColumn } from 'components/Column'
import Loading from 'components/Loading'
import { RowBetween } from 'components/Row'
import styled from 'styled-components'
import { Color } from 'theme/styled'
import { TYPE } from 'theme/theme'
import { formatDecimal } from 'utils/numberWithCommas'

import { useLoanPosition } from '../hooks'

const Header = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const Label = styled.div`
  display: flex;
  align-items: center;
`

const Value = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const Icon = styled.img<{ bgColor: Color }>`
  background-color: ${({ bgColor, theme }) => (bgColor in theme ? (theme as any)[bgColor] : theme.bg1)};
  width: 22px;
  height: 22px;
  margin-right: 4px;
  border-radius: 50%;
`

const RowBetweenStyled = styled(RowBetween)<{ bgColor: Color }>`
  background-color: ${({ bgColor, theme }) => (bgColor in theme ? (theme as any)[bgColor] : theme.bg1)};
  padding: 8px 12px;
  border-radius: 24px;
  height: 44px;
`

const SettingsBtn = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.main25};
  display: flex;
  cursor: pointer;
`

const SettingsIcon = styled.img`
  margin: auto;
  width: 11px;
  height: 11px;
`

export const LoanOverview = () => {
  const { position, loading } = useLoanPosition()

  const { body, collateralAmount } = position

  return (
    <AutoColumn gap="8px">
      <RowBetweenStyled bgColor="main15">
        <Label>
          <TokenSmallBadge variant="xfi" showLabel={false} showBg={false}>
            <TYPE.body fontWeight={500} color="main">
              XFI
            </TYPE.body>
          </TokenSmallBadge>
        </Label>

        <Value>
          <TYPE.body fontWeight={500} color="main">
            <Loading loading={loading}>{formatDecimal(collateralAmount)}</Loading>
          </TYPE.body>
          <TYPE.body fontWeight={500} color="main50">
            Collateral
          </TYPE.body>
        </Value>
      </RowBetweenStyled>

      <RowBetweenStyled bgColor="dark06">
        <Label>
          <TokenSmallBadge variant="xusd" showLabel={false} showBg={false}>
            <TYPE.body fontWeight={500} color="dark80">
              XUSD
            </TYPE.body>
          </TokenSmallBadge>
        </Label>

        <Value>
          <TYPE.body fontWeight={500} color="dark80">
            <Loading loading={loading}>{formatDecimal(body)}</Loading>
          </TYPE.body>
          <TYPE.body fontWeight={500} color="dark40">
            Total debt
          </TYPE.body>
        </Value>
      </RowBetweenStyled>
    </AutoColumn>
  )
}
