import questionIcon from 'assets/icons/question-main.svg'
import iconEsXfi from 'assets/icons/tokens/esXfi.svg'
import iconLp from 'assets/icons/tokens/lpXFI.svg'
import iconWeth from 'assets/icons/tokens/weth.svg'
import xfiIcon from 'assets/icons/tokens/xfi.svg'
import { IPickerToken } from 'components/blocks/AmountInput/useAmountInput'
import { AutoColumn } from 'components/Column'
import { ExplanationBtn } from 'components/ExplanationBtn/ExplanationBtn'
import LpXfiIcon from 'components/icons/lp-xfi'
import Loading from 'components/Loading'
import { RowBetween } from 'components/Row'
import { useXusdWethLpStakingContract } from 'constants/app-contracts'
import { Contract } from 'ethers'
import { useBalance } from 'hooks/base/useBalance'
import { useCallStaticMethod } from 'hooks/useCallStaticMethod'
import { useActiveWeb3React } from 'hooks/web3'
import { useEarnedRewards } from 'pages/Rewards/XfiRewards/XfiRewards'
import { useMemo } from 'react'
import styled from 'styled-components'
import { Color } from 'theme/styled'
import { TYPE } from 'theme/theme'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

const Header = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const Label = styled.div`
  display: flex;
  align-items: center;
`

const Value = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const Icon = styled.img<{ bgColor: Color }>`
  width: 22px;
  height: 22px;
  margin-right: 4px;
`

const RowBetweenStyled = styled(RowBetween)<{ bgColor: Color }>`
  background-color: ${({ bgColor, theme }) => (bgColor in theme ? (theme as any)[bgColor] : theme.bg1)};
  padding: 8px 12px;
  border-radius: 24px;
  height: 44px;
`

const SettingsBtn = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.main25};
  display: flex;
  cursor: pointer;
`

const SettingsIcon = styled.img`
  margin: auto;
  width: 11px;
  height: 11px;
`

export const Rounded = styled.div<{ bgColor: Color }>`
  background-color: ${({ bgColor, theme }) => (bgColor in theme ? (theme as any)[bgColor] : theme.bg1)};
  width: 22px;
  height: 22px;
  margin-right: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const deps: string[] = []

const DEFAULT_REWARDS = [ZERO, ZERO, ZERO, ZERO, ZERO, ZERO]

export const useStakingResults = () => {
  const contract = useXusdWethLpStakingContract()

  const { loading, result } = useCallStaticMethod(contract, 'getUserData', deps)

  const [lpXfiStaked, bonusPoints, vestingEarned, esXfiEarned, balanceVST, wethEarned] = useMemo(() => {
    return !loading && Array.isArray(result) ? result : DEFAULT_REWARDS
  }, [loading, result])

  return {
    loading,
    lpXfiStaked,
    bonusPoints,
    vestingEarned,
    esXfiEarned,
    balanceVST,
    wethEarned,
  }
}

export const StakingOverview = () => {
  const { loading, lpXfiStaked, bonusPoints, esXfiEarned, wethEarned } = useStakingResults()

  return (
    <>
      <Header>Overview</Header>

      <AutoColumn gap="8px">
        <RowBetweenStyled bgColor="main15">
          <Label>
            <Rounded bgColor="main15">
              <LpXfiIcon width={12} height={12} />
            </Rounded>
            <TYPE.body fontWeight={500} color="main" marginLeft="4px">
              lpXFI
            </TYPE.body>
          </Label>

          <Value>
            <TYPE.body fontWeight={500} color="main">
              <Loading loading={loading}>{formatDecimal(lpXfiStaked, 2)}</Loading>
            </TYPE.body>
            <TYPE.body fontWeight={500} color="dark40">
              Staked
            </TYPE.body>
          </Value>
        </RowBetweenStyled>

        <RowBetweenStyled bgColor="dark06">
          <Label>
            <Icon src={iconWeth} bgColor="dark15" />
            <TYPE.body fontWeight={500} color="dark80">
              WETH
            </TYPE.body>
          </Label>

          <Value>
            <TYPE.body fontWeight={500} color="dark80">
              <Loading loading={loading}>{formatDecimal(wethEarned || ZERO, 2)}</Loading>
            </TYPE.body>
            <TYPE.body fontWeight={500} color="dark40">
              Earned
            </TYPE.body>
          </Value>
        </RowBetweenStyled>

        <RowBetweenStyled bgColor="fuchsia15">
          <Label>
            <Icon src={iconEsXfi} bgColor="fuchsia15" />
            <TYPE.body fontWeight={500} color="fuchsia">
              esXFI
            </TYPE.body>
          </Label>

          <Value>
            <TYPE.body fontWeight={500} color="fuchsia">
              <Loading loading={loading}>{formatDecimal(esXfiEarned, 2)}</Loading>
            </TYPE.body>
            <TYPE.body fontWeight={500} color="fuchsia50">
              Earned
            </TYPE.body>
          </Value>
        </RowBetweenStyled>

        <RowBetweenStyled bgColor="main15">
          <Label>
            <TYPE.body fontWeight={500} color="main">
              Bonus Points
            </TYPE.body>
          </Label>

          <Value>
            <TYPE.body fontWeight={500} color="main">
              <Loading loading={loading}>{formatDecimal(bonusPoints, 2)}</Loading>
            </TYPE.body>

            <ExplanationBtn title="Bonus Points increase the share of total rewards received, as 1 Bonus Point = 1 LP XFI Staked. Bonus Points are awarded permanently at 100% APR of the number of LP XFIs staked.">
              <SettingsBtn>
                <SettingsIcon src={questionIcon} />
              </SettingsBtn>
            </ExplanationBtn>
          </Value>
        </RowBetweenStyled>
      </AutoColumn>
    </>
  )
}

export const SimpleStakingOverview = ({ contract, model }: { contract: Contract | null; model?: IPickerToken }) => {
  const { result: earned, loading } = useEarnedRewards(contract)

  const { account } = useActiveWeb3React()

  const balance = useBalance(contract, account)

  return (
    <>
      <Header>Overview</Header>

      <AutoColumn gap="8px">
        <RowBetweenStyled bgColor="main15">
          <Label>
            <Icon src={iconLp} bgColor="main15" />
            <TYPE.body fontWeight={500} color="appViolet">
              {model?.symbol}
            </TYPE.body>
          </Label>

          <Value>
            <TYPE.body fontWeight={500} color="appViolet">
              {formatDecimal(balance, 2)}
            </TYPE.body>
            <TYPE.body fontWeight={500} color="dark15">
              Staked
            </TYPE.body>
          </Value>
        </RowBetweenStyled>

        <RowBetweenStyled bgColor="main15">
          <Label>
            <Icon src={xfiIcon} bgColor="transparent" />
            <TYPE.body fontWeight={500} color="main">
              XFI
            </TYPE.body>
          </Label>

          <Value>
            <TYPE.body fontWeight={500} color="main">
              <Loading loading={loading}>{formatDecimal(earned || ZERO, 2)}</Loading>
            </TYPE.body>
            <TYPE.body fontWeight={500} color="main">
              Earned
            </TYPE.body>
          </Value>
        </RowBetweenStyled>
      </AutoColumn>
    </>
  )
}
