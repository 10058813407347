import { ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { AmountInputWithMax } from 'components/blocks/AmountInput/AmountInput'
import { TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { ButtonPrimary } from 'components/Button'
import { CardCenteredGap } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { FormPageWrapper } from 'components/Forms/styled'
import { TransactionInfo } from 'components/TransactionInfo/TransactionInfo'
import { Contract } from 'ethers'
import { useCallStaticMethod } from 'hooks/useCallStaticMethod'
import { useActiveWeb3React } from 'hooks/web3'
import { useClaimRewards } from 'pages/Rewards/hooks'
import { PendingRewardsView, RewardsXfiHeader } from 'pages/Rewards/PendingView'
import { useMemo, useState } from 'react'
import { ZERO } from 'utils/isZero'

const defaultRightToken = {
  symbol: TokenSymbol.xfi,
}

export const useEarnedRewards = (contract: Contract | null) => {
  const { account } = useActiveWeb3React()

  const deps = useMemo(() => [account], [account])

  return useCallStaticMethod(contract, 'earned', deps)
}

export default function XfiRewards({ contract }: { contract: Contract | null }) {
  const { result } = useEarnedRewards(contract)

  const earned = result || ZERO

  const noValue = !earned || earned.isZero()
  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const { pending, action, isError, txInfo, calledWallet } = useClaimRewards(contract, earned, setPendingTx)

  if (pendingTx) {
    return (
      <PendingRewardsView
        onBack={() => setPendingTx('')}
        amount={earned}
        color="orange"
        hash={pendingTx}
        token={TokenSymbol.xfi}
        txInfo={txInfo}
        header={<RewardsXfiHeader />}
      />
    )
  }

  return (
    <FormPageWrapper>
      <CardCenteredGap gap="16px">
        <RewardsXfiHeader />

        <AutoColumn>
          <AmountInputWithMax
            label="Claim"
            showBalanceRow={false}
            inputValue={earned}
            disabled
            rightToken={defaultRightToken}
            decimals={18}
          />
        </AutoColumn>

        <TransactionInfo info={txInfo} />

        <ConfirmInWalletBlock calledWallet={calledWallet}>
          {noValue ? (
            <ButtonPrimary disabled={noValue}>No Rewards</ButtonPrimary>
          ) : (
            <ButtonPrimary onClick={action} disabled={isError}>
              <FormActionBtn pending={pending} txInfo={txInfo} labelActive="Get reward" labelInProgress="Claiming" />
            </ButtonPrimary>
          )}
        </ConfirmInWalletBlock>
      </CardCenteredGap>
    </FormPageWrapper>
  )
}
