import * as React from 'react'
import { ThemeColors } from 'theme/styled'

function Icon(props: React.SVGProps<SVGSVGElement> & { color?: ThemeColors }, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="80" height="80" rx="40" fill="white" />
      <path
        d="M25.1218 23.025L25.0769 23L25.0322 23.0254L17.0444 27.5628L17 27.588V27.6381V36.6707V36.721L17.0446 36.7461L23.6833 40.4988L17.0446 44.2515L17 44.2767V44.3269V53.2866V53.3362L17.0438 53.3615L25.0297 57.974L25.0746 58L25.12 57.9747L31.9271 54.1614L39.9567 49.7556L47.9857 54.1617L54.8069 57.9736L54.8519 57.9988L54.8967 57.9731L62.9558 53.3635L63 53.3382V53.2883V44.326V44.2757L62.9553 44.2506L56.2858 40.4988L62.9553 36.7471L63 36.7219V36.6716V27.6363V27.586L62.9553 27.5609L54.8942 23.0263L54.8496 23.0012L54.805 23.0261L39.9463 31.2943L25.1218 23.025ZM18.4387 29.2044L31.0778 36.3189L25.1162 39.6888L18.4387 35.9143V29.2044ZM32.5116 35.5076L19.1519 27.9876L25.0835 24.6183L38.5077 32.1065L32.5116 35.5076ZM45.9669 36.3099L39.9467 39.6235L33.9556 36.3099L39.9467 32.9115L45.9669 36.3099ZM61.5612 35.9133L54.8477 39.6898L48.8553 36.3189L61.5612 29.2015V35.9133ZM60.8402 27.9891L47.4162 35.5086L41.388 32.1057L54.8457 24.617L60.8402 27.9891ZM60.8424 44.6801L54.8488 48.0516L41.3925 40.437L47.4109 37.1244L60.8424 44.6801ZM55.5671 55.9649V49.2655L61.5612 45.8938V52.5363L55.5671 55.9649ZM40.6652 41.6459L54.1283 49.2644V55.9791L40.6652 48.4555V41.6459ZM39.2265 41.6473V48.4561L25.7995 55.9777V49.2639L39.2265 41.6473ZM25.0804 48.0504L19.1497 44.6815L32.5169 37.1254L38.5032 40.4363L25.0804 48.0504ZM18.4387 52.5383V45.8997L24.3608 49.2636V55.9588L18.4387 52.5383Z"
        fill="#0CC2FF"
        stroke="#0CC2FF"
        strokeWidth="0.193374"
      />
    </svg>
  )
}

const ForwardRef = React.forwardRef(Icon)
const MemoForwardRef = React.memo(ForwardRef)

export default MemoForwardRef
