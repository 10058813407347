import { BigNumber } from 'ethers'
import { ZERO } from 'utils/isZero'

export const PRICE_FEED_DECIMALS = 8

const PRICE_FEED_DIVIDER = BigNumber.from(10).pow(PRICE_FEED_DECIMALS)

const min = (a: BigNumber, b: BigNumber) => (a.lt(b) ? a : b)
const max = (a: BigNumber, b: BigNumber) => (a.gt(b) ? a : b)

const getConvertedPriceMul = (priceContracted: BigNumber) => priceContracted.mul(PRICE_FEED_DIVIDER)
const getConvertedPriceDev = (priceContracted: BigNumber) => priceContracted.div(PRICE_FEED_DIVIDER)

export const getMaxAllowedXfi = (
  amountXUsd: BigNumber = ZERO,
  getPriceFeeds: BigNumber,
  collateralRatio: BigNumber
) => {
  const allowed = getAllowedXfi(amountXUsd, getPriceFeeds, collateralRatio)

  console.log(
    'getMaxAllowedXfi',
    allowed.toString(),
    amountXUsd.toString(),
    collateralRatio.toString(),
    getPriceFeeds.toString()
  )

  return allowed
}

export const getAllowedXfi = (amountXUsd: BigNumber = ZERO, getPriceFeeds: BigNumber, collateralRatio: BigNumber) => {
  const allowed = getPriceFeeds.isZero() ? ZERO : amountXUsd.mul(collateralRatio).div(getPriceFeeds)

  return getConvertedPriceMul(allowed)
}

export const getCollateralRatio = (
  amountXfi: BigNumber = ZERO,
  amountXUsd: BigNumber = ZERO,
  getPriceFeeds: BigNumber = ZERO
) => {
  const ratio = amountXUsd.isZero() ? ZERO : getXfiPrice(amountXfi, getPriceFeeds).div(amountXUsd)

  return ratio
}

export const getAllowedXUsd = (amountXFI: BigNumber = ZERO, getPriceFeeds: BigNumber, collateralRatio: BigNumber) => {
  const allowed = getPriceFeeds.isZero() ? ZERO : getXfiPrice(amountXFI, getPriceFeeds).div(collateralRatio)

  return allowed
}

export const getXfiPrice = (amountXfi: BigNumber, getPriceFeeds: BigNumber) => {
  const price = amountXfi.isZero() ? ZERO : amountXfi.mul(getPriceFeeds)

  return getConvertedPriceDev(price)
}
