import { ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { AmountInputWithMax } from 'components/blocks/AmountInput/AmountInput'
import { TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { ButtonPrimary } from 'components/Button'
import { CardCenteredGap } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { FormPageWrapper } from 'components/Forms/styled'
import { useStakingResults } from 'components/StakingOverview/StakingOverview'
import { TransactionInfo } from 'components/TransactionInfo/TransactionInfo'
import { useXusdWethLpStakingContract } from 'constants/app-contracts'
import { useClaimRewards } from 'pages/Rewards/hooks'
import { PendingRewardsView, RewardsLpXFIHeader } from 'pages/Rewards/PendingView'
import { useState } from 'react'

const defaultRightToken = {
  symbol: TokenSymbol.weth,
}

export default function RewardsWethXusd() {
  const { wethEarned } = useStakingResults()

  const noValue = !wethEarned || wethEarned.isZero()
  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const contract = useXusdWethLpStakingContract()

  const { pending, action, isError, txInfo, calledWallet } = useClaimRewards(contract, wethEarned, setPendingTx)

  if (pendingTx) {
    return (
      <PendingRewardsView
        onBack={() => setPendingTx('')}
        amount={wethEarned}
        color="orange"
        hash={pendingTx}
        token={TokenSymbol.weth}
        txInfo={txInfo}
        header={<RewardsLpXFIHeader />}
      />
    )
  }

  return (
    <FormPageWrapper>
      <CardCenteredGap gap="16px">
        <RewardsLpXFIHeader />

        <AutoColumn>
          <AmountInputWithMax
            label="Claim"
            showBalanceRow={false}
            inputValue={wethEarned}
            disabled
            rightToken={defaultRightToken}
            decimals={18}
          />
        </AutoColumn>

        <TransactionInfo info={txInfo} />

        <ConfirmInWalletBlock calledWallet={calledWallet}>
          {noValue ? (
            <ButtonPrimary disabled={noValue}>No Rewards</ButtonPrimary>
          ) : (
            <ButtonPrimary onClick={action} disabled={isError}>
              <FormActionBtn pending={pending} txInfo={txInfo} labelActive="Get reward" labelInProgress="Claiming" />
            </ButtonPrimary>
          )}
        </ConfirmInWalletBlock>
      </CardCenteredGap>
    </FormPageWrapper>
  )
}
