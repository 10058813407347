import { TransactionResponse } from '@ethersproject/providers'
import { ApproveCheckerStaking, ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { AmountInputWithMax } from 'components/blocks/AmountInput/AmountInput'
import { IPickerToken } from 'components/blocks/AmountInput/useAmountInput'
import { TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { TransactionInfo } from 'components/TransactionInfo/TransactionInfo'
import {
  useEMpxXfiContract,
  useEMpxXfiLPAddress,
  useMpxStakingContract,
  useStakingXUSDUsdtPContract,
  useStakingXusdWethLpAddress,
  useStakingXUSDWethLPContract,
  useXusdUsdLpStakingContract,
  useXusdUsdtLpAddress,
  useXusdWethLpStakingContract,
} from 'constants/app-contracts'
import { TxTemplateTypes } from 'constants/transactions'
import { BigNumber, Contract } from 'ethers'
import { useTxTemplate } from 'hooks/base/tx-template'
import { useBalance, useDecimals } from 'hooks/base/useBalance'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useMemo, useState } from 'react'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

const useStakingTokens = () => {
  const mpxAddress = useEMpxXfiLPAddress()
  const xusdUsdtAddress = useXusdUsdtLpAddress()
  const wethXusdAddress = useStakingXusdWethLpAddress()

  const wethXusdC = useStakingXUSDWethLPContract()
  const xUsdUsdtC = useStakingXUSDUsdtPContract()
  const mpxC = useEMpxXfiContract()

  const mpxStakingC = useMpxStakingContract()
  const wethXusdStakingC = useXusdWethLpStakingContract()
  const xUsdUsdtcStakingC = useXusdUsdLpStakingContract()

  return useMemo(
    () => [
      {
        address: wethXusdAddress,
        contract: wethXusdC,
        stakingContract: wethXusdStakingC,
        symbol: 'lpXFI' as TokenSymbol,
      },
      {
        address: xusdUsdtAddress,
        symbol: 'lpUSD' as TokenSymbol,
        contract: xUsdUsdtC,
        stakingContract: xUsdUsdtcStakingC,
      },
      {
        address: mpxAddress,
        symbol: 'lpMPX' as TokenSymbol,
        contract: mpxC,
        stakingContract: mpxStakingC,
      },
    ],
    [
      mpxAddress,
      xusdUsdtAddress,
      wethXusdAddress,
      wethXusdC,
      xUsdUsdtC,
      mpxC,
      mpxStakingC,
      wethXusdStakingC,
      xUsdUsdtcStakingC,
    ]
  )
}

const useStaking = (contract: Contract | null, amount: BigNumber | undefined, setPendingTx: (v: string) => void) => {
  const value = useMemo(() => (amount ? amount : ZERO), [amount])

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.stake(value)
  }, [contract, value])

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return useTxTemplate(
    TxTemplateTypes.Staking,
    `$stake_${value.toString()}`,
    `Staked ${formatDecimal(value)} LP tokens`,
    dataFunc,
    setTx
  )
}

export const useStakingToken = () => {
  const stakingTokens = useStakingTokens()
  const [tokenIn, setTokenIn] = useState<string>(stakingTokens[0].symbol)

  const tokenInModel = useMemo(
    () => stakingTokens.find((item) => item.address === tokenIn || item.symbol === tokenIn),
    [tokenIn, stakingTokens]
  )

  return {
    contract: tokenInModel?.contract as Contract,
    setTokenIn,
    tokenIn: tokenInModel,
    stakingTokens,
    lpStakingContract: tokenInModel?.stakingContract as Contract,
  }
}

export const StakeBlock = ({
  setPendingTx,
  amount,
  setAmount,
  contract,
  lpStakingContract,
  stakingTokens,
  tokenIn,
  setTokenIn,
}: {
  setPendingTx: (v: string) => void
  amount?: BigNumber
  setAmount: (v?: BigNumber) => void
  contract: Contract
  lpStakingContract: Contract
  stakingTokens: IPickerToken[]
  tokenIn: IPickerToken
  setTokenIn: (v: string) => void
}) => {
  const { account } = useActiveWeb3React()

  const balance = useBalance(contract, account)
  const decimals = useDecimals(contract)

  const noValue = !amount || amount.isZero()

  const { pending, action, isError, txInfo, calledWallet } = useStaking(lpStakingContract, amount, setPendingTx)

  return (
    <>
      <AutoColumn>
        <AmountInputWithMax
          inputValue={amount}
          setInputValue={(v) => v && setAmount(v)}
          decimals={decimals}
          max={balance}
          rightTokenOptions={stakingTokens}
          rightToken={tokenIn}
          onChangeRightToken={setTokenIn}
        />
      </AutoColumn>

      <TransactionInfo info={txInfo} />

      {lpStakingContract?.address && (
        <ApproveCheckerStaking border={balance} token={contract?.address} contractAddress={lpStakingContract?.address}>
          <ConfirmInWalletBlock calledWallet={calledWallet}>
            {noValue ? (
              <ButtonPrimary disabled={noValue}>Enter an amount</ButtonPrimary>
            ) : (
              <ButtonPrimary onClick={action} disabled={isError}>
                <FormActionBtn pending={pending} txInfo={txInfo} labelActive="Stake" labelInProgress="Staking" />
              </ButtonPrimary>
            )}
          </ConfirmInWalletBlock>
        </ApproveCheckerStaking>
      )}
    </>
  )
}
