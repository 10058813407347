import { FormHeader } from 'components/FormHeader/FormHeader'
import { useMenu } from 'constants/menu'

export const XUsdHeader = () => {
  const menu = useMenu()

  return (
    <FormHeader
      icon={menu.xusd.src}
      label={menu.xusd.label}
      explanation="On this page you can manage your credit position: repay loan, take additional loan, add collateral, remove collateral"
    />
  )
}
