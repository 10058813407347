import { TransactionResponse } from '@ethersproject/providers'
import { useCreditContract } from 'constants/app-contracts'
import { TxTemplateTypes } from 'constants/transactions'
import { BigNumber } from 'ethers'
import { useTxTemplate } from 'hooks/base/tx-template'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useMemo } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

import { getAllowedXfi, getAllowedXUsd } from './utils'

export const useLoadParams = () => {
  const contract = useCreditContract()

  const getPriceFeeds = useSingleCallResult(contract, 'getPriceFeeds')

  const collateralRatio = useSingleCallResult(contract, 'collateralRatio')
  const liquidationFee = useSingleCallResult(contract, 'liquidationPenaltyPercentagePoint')
  const borrowFee = useSingleCallResult(contract, 'borrowFeePercentagePoint')
  const interest = useSingleCallResult(contract, 'interestPercentagePoints')

  const { account } = useActiveWeb3React()

  const deps = useMemo(() => [account], [account])
  const totalDebt = useSingleCallResult(contract, 'getTotalDebt', deps)

  return useMemo(() => {
    return {
      getPriceFeeds: getPriceFeeds?.result?.[0] || ZERO,
      collateralRatio: collateralRatio?.result?.[0] || ZERO,
      totalDebt: totalDebt?.result?.[0] || ZERO,
      liquidationFee: liquidationFee?.result?.[0] || ZERO,
      borrowFee: borrowFee?.result?.[0] || ZERO,
      interest: interest?.result?.[0] || ZERO,
      loading:
        totalDebt.loading ||
        collateralRatio.loading ||
        getPriceFeeds.loading ||
        liquidationFee.loading ||
        borrowFee.loading ||
        interest.loading,
    }
  }, [getPriceFeeds, collateralRatio, totalDebt, liquidationFee, borrowFee, interest])
}

export const useRemainingXUsd = () => {
  const { getPriceFeeds, collateralRatio, loading: loadingParams } = useLoadParams()

  const { position, loading: loadingP } = useLoanPosition()

  const { collateralAmount, body } = position

  const remaining = useMemo(() => {
    return getAllowedXUsd(collateralAmount, getPriceFeeds, collateralRatio).sub(body)
  }, [collateralAmount, getPriceFeeds, collateralRatio, body])

  return {
    remaining,
    loading: loadingP || loadingParams,
  }
}

export const useOverpaidXfi = () => {
  const { getPriceFeeds, collateralRatio, loading: loadingParams } = useLoadParams()

  const { position, loading: loadingP } = useLoanPosition()

  const { collateralAmount, body } = position

  const allowed = useMemo(() => {
    return getAllowedXfi(body, getPriceFeeds, collateralRatio)
  }, [body, getPriceFeeds, collateralRatio])

  return useMemo(() => {
    return {
      remaining: collateralAmount.sub(allowed),
      loading: loadingP || loadingParams,
    }
  }, [collateralAmount, allowed, loadingP, loadingParams])
}

interface IPosition {
  body: BigNumber
  borrowFee: BigNumber
  collateralAmount: BigNumber
  interest: BigNumber
  creationTime: BigNumber
  isInitialized: boolean
  lastUpdateTime: number
  liquidationPrice: BigNumber
}

export const useLoanPosition = (): {
  position: IPosition
  loading: boolean
} => {
  const contract = useCreditContract()

  const { account } = useActiveWeb3React()

  const deps = useMemo(() => [account], [account])

  const getPosition = useSingleCallResult(contract, 'getPosition', deps)

  return useMemo(() => {
    return {
      position: getPosition?.result?.[0] || {
        body: ZERO,
        borrowFee: ZERO,
        collateralAmount: ZERO,
        interest: ZERO,
        creationTime: ZERO,
        isInitialized: false,
        lastUpdateTime: ZERO,
        liquidationPrice: ZERO,
      },
      loading: getPosition.loading,
    }
  }, [getPosition])
}

export const useTakeLoan = (
  amountXusd: BigNumber = ZERO,
  amountXfi: BigNumber = ZERO,
  setPendingTx: (v: string) => void
) => {
  const contract = useCreditContract()

  const dataFunc = useCallback(async () => {
    return { ...(await contract?.populateTransaction.takeLoan(amountXusd)), value: amountXfi }
  }, [contract, amountXusd, amountXfi])

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return useTxTemplate(
    TxTemplateTypes.Staking,
    `$take_loan_${amountXusd.toString()}`,
    `Taken loan for ${formatDecimal(amountXusd)} xUSD`,
    dataFunc,
    setTx
  )
}

export const useRePayLoan = (amount: BigNumber = ZERO, setPendingTx: (v: string) => void) => {
  const contract = useCreditContract()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.repayLoan(amount)
  }, [contract, amount])

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return useTxTemplate(
    TxTemplateTypes.Staking,
    `$repay_loan_${amount.toString()}`,
    `Repay loan for ${formatDecimal(amount)} xUSD`,
    dataFunc,
    setTx
  )
}

export const useTakeAdditionalLoan = (amount: BigNumber = ZERO, setPendingTx: (v: string) => void) => {
  const contract = useCreditContract()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.takeAdditionalLoan(amount)
  }, [contract, amount])

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return useTxTemplate(
    TxTemplateTypes.Staking,
    `$take_additional_loan_${amount.toString()}`,
    `Take additional loan for ${formatDecimal(amount)} XFI`,
    dataFunc,
    setTx
  )
}

export const useAddCollateral = (amountXfi: BigNumber = ZERO, setPendingTx: (v: string) => void) => {
  const contract = useCreditContract()

  const dataFunc = useCallback(async () => {
    return { ...(await contract?.populateTransaction.addCollateral()), value: amountXfi }
  }, [contract, amountXfi])

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return useTxTemplate(
    TxTemplateTypes.Staking,
    `$take_loan_${amountXfi.toString()}`,
    `Added collateral for ${formatDecimal(amountXfi)} XFI`,
    dataFunc,
    setTx
  )
}

export const useWithdrawCollateral = (amount: BigNumber = ZERO, setPendingTx: (v: string) => void) => {
  const contract = useCreditContract()

  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.withdrawCollateralFromPosition(amount)
  }, [contract, amount])

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return useTxTemplate(
    TxTemplateTypes.Staking,
    `$withdraw_collateral_loan_${amount.toString()}`,
    `Withdraw collateral ${formatDecimal(amount)} XFI`,
    dataFunc,
    setTx
  )
}
