import * as React from 'react'
import { useTheme } from 'styled-components'
import { ThemeColors } from 'theme/styled'

function Icon(props: React.SVGProps<SVGSVGElement> & { color?: ThemeColors }, svgRef?: React.Ref<SVGSVGElement>) {
  const theme = useTheme()

  const color = props.color && (theme as any)[props.color] ? theme[props.color] : theme.fuchsia

  return (
    <svg ref={svgRef} {...props} viewBox="0 0 1001 1001" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.796875" y="0.904297" width="999.997" height="999.997" rx="499.998" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M500.462 340.664L635.095 206.031L795.461 206.029L795.461 366.397L660.827 501.03L795.46 635.663L795.461 796.031L635.095 796.029L500.462 661.396L365.829 796.029L205.461 796.029L205.463 635.663L340.096 501.03L205.463 366.397L205.461 206.031L365.828 206.031L500.462 340.664ZM468.628 372.498L347.181 251.051L250.481 251.051L250.482 347.749L371.93 469.196L468.628 372.498ZM403.764 501.03L500.462 404.332L597.16 501.03L500.462 597.728L403.764 501.03ZM371.93 532.864L250.483 654.311L250.481 751.009L347.181 751.009L468.628 629.562L371.93 532.864ZM532.296 629.562L653.743 751.009L750.441 751.01L750.441 654.311L628.993 532.864L532.296 629.562ZM628.994 469.196L532.296 372.498L653.743 251.051L750.441 251.05L750.441 347.749L628.994 469.196Z"
      />
    </svg>
  )
}

const ForwardRef = React.forwardRef(Icon)
const MemoForwardRef = React.memo(ForwardRef)

export default MemoForwardRef
