import { Stack } from '@mui/material'
import TokenLargeBadge from 'components/badges/TokenLargeBadge/TokenLargeBadge'
import TokenLargeWhiteBgBadge from 'components/badges/TokenLargeWhiteBgBadge/TokenLargeWhiteBgBadge'
import { Box, Menu } from 'components/MUI'
import styled from 'styled-components'

import { IPickerToken } from './useAmountInput'
import { IAppToken } from './useAppCoins'

interface IRightTokenSelector {
  value: IAppToken
  options?: IPickerToken[]
  onChangeRightToken?: (addressOrSymbol: string) => void
}

const TokenLargeBadgeStyled = styled(TokenLargeBadge)`
  width: 100%;
`

export const RightTokenSelector = ({ value, options, onChangeRightToken }: IRightTokenSelector) => {
  if (options && options.length > 1) {
    return (
      <Menu trigger={() => <TokenLargeWhiteBgBadge asset={value} variant={value.symbol} />} isChildrenCloseMenu>
        <Stack gap="6px">
          {options.map((item, index) => (
            <Box
              key={`${item.symbol}_${index}`}
              onClick={() => {
                onChangeRightToken?.(item.address || item.symbol)
              }}
              sx={{ cursor: 'pointer' }}
            >
              <TokenLargeBadgeStyled variant={item.symbol} asset={item as IAppToken} />
            </Box>
          ))}
        </Stack>
      </Menu>
    )
  } else {
    return <TokenLargeBadge variant={value.symbol} asset={value} />
  }
}
