import { Divider } from 'pages/Staking/styled'

import { LoanOverview } from './LoanOverview/LoanOverview'
import { LoanPositionInfo } from './LoanPositionInfo/LoanPositionInfo'

export const LoanInfo = () => {
  return (
    <>
      <LoanOverview />

      <Divider />

      <LoanPositionInfo showInterest={true} showLiquidationRatio={true} />
    </>
  )
}
