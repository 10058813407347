import { CardCenteredGap } from 'components/Card'
import { FormPageWrapper } from 'components/Forms/styled'
import styled from 'styled-components'

import { XUsdHeader } from './XUsdHeader'
import XUsdLoan from './XUsdLoan'

export default function XUSD() {
  return (
    <StyledWrapper>
      <FormPageWrapper>
        <CardCenteredGap gap="16px">
          <XUsdHeader />

          <XUsdLoan />
        </CardCenteredGap>
      </FormPageWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToTablet`
    margin: 70px auto 110px;
  `};

  ${({ theme }) => theme.mediaWidth.upToPhone`
    margin: 0;
  `};
`
