import styled from 'styled-components'

export const PageWrapper = styled.div<{ maxWidth?: string }>`
  flex: 1;
  justify-content: center;
  margin: auto;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : '600px')};
  flex-direction: column;
  display: flex;
`
