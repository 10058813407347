import * as React from 'react'
import { ThemeColors } from 'theme/styled'

function Icon(props: React.SVGProps<SVGSVGElement> & { color?: ThemeColors }, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      ref={svgRef}
      {...props}
      width="1001"
      height="1001"
      viewBox="0 0 1001 1001"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.265625" y="0.225586" width="999.997" height="999.997" rx="499.998" fill="black" />
      <path
        d="M714.488 446.375L554.122 286.009L634.906 205.226L795.272 205.224L795.272 365.591L714.488 446.375Z"
        fill="#0CC2FF"
      />
      <path
        d="M714.486 554.073L554.121 714.439L634.906 795.224L795.273 795.225L795.272 634.858L714.486 554.073Z"
        fill="#0CC2FF"
      />
      <path
        d="M365.62 795.224L660.621 500.223L365.624 205.226L205.273 205.224L205.273 365.592L339.906 500.225L205.273 634.858L205.271 795.224L365.62 795.224Z"
        fill="#0CC2FF"
      />
    </svg>
  )
}

const ForwardRef = React.forwardRef(Icon)
const MemoForwardRef = React.memo(ForwardRef)

export default MemoForwardRef
