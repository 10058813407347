import { GreyCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import Loading from 'components/Loading'
import { RowBetween } from 'components/Row'
import { useMemo } from 'react'
import styled from 'styled-components'
import { formatDecimal } from 'utils/numberWithCommas'

import { useLoadParams, useLoanPosition, useRemainingXUsd } from '../hooks'
import { getCollateralRatio, getXfiPrice, PRICE_FEED_DECIMALS } from '../utils'

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.dark40};
`

const Value = styled.div`
  font-weight: 500;
  font-size: 14px;
`

const ColumnStyled = styled(AutoColumn)`
  padding: 6px 0;
`

export const LoanPositionInfo = ({
  showLiquidationRatio = false,
  showInterest = false,
  showXUsdLeft = false,
}: {
  showXUsdLeft?: boolean
  showLiquidationRatio?: boolean
  showInterest?: boolean
}) => {
  const { remaining, loading } = useRemainingXUsd()

  const { position, loading: loadingP } = useLoanPosition()
  const { getPriceFeeds, loading: loadingParams, collateralRatio } = useLoadParams()

  const { liquidationPrice, interest, body, borrowFee, collateralAmount } = position

  const liquidationRatio = useMemo(() => {
    return getCollateralRatio(collateralAmount, body, liquidationPrice)
  }, [collateralAmount, body, liquidationPrice])

  const collateralPrice = useMemo(() => {
    return getXfiPrice(collateralAmount, getPriceFeeds)
  }, [collateralAmount, getPriceFeeds])

  const liquidationPriceTotal = useMemo(() => {
    return getXfiPrice(collateralAmount, liquidationPrice)
  }, [collateralAmount, liquidationPrice])

  return (
    <GreyCard>
      <ColumnStyled gap="6px">
        {showXUsdLeft && (
          <RowBetween>
            <Label>XUSD left to borrow</Label>

            <Value>
              <Loading loading={loading}>{formatDecimal(remaining)}</Loading>
            </Value>
          </RowBetween>
        )}

        <RowBetween>
          <Label>Collateral price</Label>

          <Value>
            <Loading loading={loadingP || loadingParams}>
              $ {formatDecimal(collateralPrice, 2)} ($ {formatDecimal(getPriceFeeds, 2, PRICE_FEED_DECIMALS)} per XFI)
            </Loading>
          </Value>
        </RowBetween>

        <RowBetween>
          <Label>Borrow amount</Label>

          <Value>
            <Loading loading={loadingP}>$ {formatDecimal(body)}</Loading>
          </Value>
        </RowBetween>

        <RowBetween>
          <Label>Collateral ratio</Label>

          <Value>
            <Loading loading={loadingP}>{formatDecimal(collateralRatio * 100, 2, 0)}%</Loading>
          </Value>
        </RowBetween>

        {showLiquidationRatio && (
          <RowBetween>
            <Label>Liquidation ratio</Label>

            <Value>
              <Loading loading={loadingP || loadingParams}>{formatDecimal(liquidationRatio.mul(100), 2, 0)}%</Loading>
            </Value>
          </RowBetween>
        )}

        <RowBetween>
          <Label>Liquidation price</Label>

          <Value>
            <Loading loading={loadingP}>
              $ {formatDecimal(liquidationPriceTotal)} ($ {formatDecimal(liquidationPrice, 2, PRICE_FEED_DECIMALS)} per
              XFI)
            </Loading>
          </Value>
        </RowBetween>

        {showInterest && (
          <>
            <RowBetween>
              <Label>Borrow Fee</Label>

              <Value>
                <Loading loading={loadingP}>{formatDecimal(borrowFee, 2)} xUSD (1%)</Loading>
              </Value>
            </RowBetween>

            <RowBetween>
              <Label>Interest</Label>

              <Value>
                <Loading loading={loadingP}>{formatDecimal(interest, 2)}%</Loading>
              </Value>
            </RowBetween>
          </>
        )}
      </ColumnStyled>
    </GreyCard>
  )
}
