import { AppToggler } from 'components/AppToggler/AppToggler'
import { CardCenteredGap } from 'components/Card'
import { FormPageWrapper } from 'components/Forms/styled'
import { useMpxStakingContract, useXusdUsdLpStakingContract } from 'constants/app-contracts'
import { useLocalStorage } from 'hooks/useLocalStorage'
import RewardsWethXusd from 'pages/Rewards/RewardsWethXusd/RewardsWethXusd'
import XfiRewards from 'pages/Rewards/XfiRewards/XfiRewards'

import { RewardsLpXFIHeader, RewardsXfiHeader } from './PendingView'

const TAB_IDS = {
  lpXFI: 'lpXFI',
  lpUSD: 'lpUSD',
  eMPX: 'eMPX',
}

const TABS = [
  {
    id: TAB_IDS.lpXFI,
    title: 'lpXFI',
  },
  {
    id: TAB_IDS.lpUSD,
    title: 'lpUSD',
  },
  {
    id: TAB_IDS.eMPX,
    title: 'eMPX',
  },
]

export default function Rewards() {
  const [tab, setTab] = useLocalStorage<string>('REWARDS_TAB', TABS[0].id)

  const contractXusdUsdtStaking = useXusdUsdLpStakingContract()
  const contractMpxStaking = useMpxStakingContract()

  return (
    <FormPageWrapper>
      <CardCenteredGap gap="16px">
        {tab === TAB_IDS.lpXFI ? <RewardsLpXFIHeader /> : <RewardsXfiHeader />}

        <AppToggler tab={tab} setTab={setTab} tabs={TABS} />

        {tab === TAB_IDS.lpXFI && <RewardsWethXusd />}
        {tab === TAB_IDS.lpUSD && <XfiRewards contract={contractXusdUsdtStaking} />}
        {tab === TAB_IDS.eMPX && <XfiRewards contract={contractMpxStaking} />}
      </CardCenteredGap>
    </FormPageWrapper>
  )
}
