import { AppToggler } from 'components/AppToggler/AppToggler'
import { AutoColumn } from 'components/Column'
import { useLocalStorage } from 'hooks/useLocalStorage'

import { LoanInfo } from './LoanInfo'
import { ManageLoan } from './ManageLoan'

const TAB_IDS = {
  INFO: 'info',
  MANAGE: 'manage',
}

const TABS = [
  {
    id: TAB_IDS.INFO,
    title: 'Info',
  },
  {
    id: TAB_IDS.MANAGE,
    title: 'Manage',
  },
]

export const TakenLoan = () => {
  const [tab, setTab] = useLocalStorage<string>('XUSD_TAB', TABS[0].id)

  return (
    <>
      <AutoColumn gap="16px">
        <AppToggler tab={tab} setTab={setTab} tabs={TABS} />

        {tab === TAB_IDS.MANAGE && <ManageLoan />}
        {tab === TAB_IDS.INFO && <LoanInfo />}
      </AutoColumn>
    </>
  )
}
