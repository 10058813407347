import { GreyCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import Loading from 'components/Loading'
import { RowBetween } from 'components/Row'
import styled from 'styled-components'
import { formatDecimal } from 'utils/numberWithCommas'

import { useLoadParams } from '../hooks'
import { PRICE_FEED_DECIMALS } from '../utils'

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.dark40};
`

const Value = styled.div`
  font-weight: 500;
  font-size: 14px;
`

const ColumnStyled = styled(AutoColumn)`
  padding: 6px 0;
`

export const XUsdBlock = () => {
  const { loading, interest, borrowFee, liquidationFee, collateralRatio, getPriceFeeds } = useLoadParams()

  return (
    <GreyCard>
      <ColumnStyled gap="6px">
        <RowBetween>
          <Label>Minimum collateral ratio</Label>

          <Value>
            <Loading loading={loading}>{formatDecimal(collateralRatio * 100, 2, 0)}%</Loading>
          </Value>
        </RowBetween>

        <RowBetween>
          <Label>Liquidation fee</Label>

          <Value>
            <Loading loading={loading}>{formatDecimal(liquidationFee, 2, 2)}%</Loading>
          </Value>
        </RowBetween>

        <RowBetween>
          <Label>Borrow fee</Label>

          <Value>
            <Loading loading={loading}>{formatDecimal(borrowFee, 2, 2)}%</Loading>
          </Value>
        </RowBetween>

        <RowBetween>
          <Label>Interest</Label>

          <Value>
            <Loading loading={loading}>{formatDecimal(interest, 2, 2)}%</Loading>
          </Value>
        </RowBetween>

        <RowBetween>
          <Label>Price</Label>

          <Loading loading={loading}>
            <Value>$ {formatDecimal(getPriceFeeds, 2, PRICE_FEED_DECIMALS)}</Value>
          </Loading>
        </RowBetween>
      </ColumnStyled>
    </GreyCard>
  )
}
