import { Typography } from '@mui/material'
import { AppGetSwitcher, getTargetBridgeChain } from 'components/AppGetSwitcher/AppGetSwitcher'
import { ApproveCheckerDeBridge, ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { AppToggler } from 'components/AppToggler/AppToggler'
import { AmountInputWithBalance } from 'components/blocks/AmountInput/AmountInput'
import { AmountValueInput } from 'components/blocks/AmountInput/styles'
import { TokenSymbol } from 'components/blocks/AmountInput/useAppCoins'
import { ButtonViolet } from 'components/Button'
import { CardCenteredGap, GreyCard } from 'components/Card'
import Column from 'components/Column'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { TransactionInfo } from 'components/TransactionInfo/TransactionInfo'
import { WarningBlock } from 'components/WarningBlock/WarningBlock'
import { useBridgableWXfiAddress } from 'constants/app-contracts'
import { CHAIN_INFO, SupportedChainId } from 'constants/chainsinfo'
import { BigNumber } from 'ethers'
import { useTokenBalance } from 'hooks/base/token'
import { useApiCall } from 'hooks/useApiCall'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useActiveWeb3React } from 'hooks/web3'
import { Dots } from 'pages/Pool/styleds'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { TYPE } from 'theme/theme'
import { fullDate } from 'utils/date'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import { BridgeHeader, PendingBridgeView } from './PendingView'
import { PageWrapper } from './styled'
import { useAllDeBridgeTxs, useClaimDeBridgeAction, useDeBridgeAction, useSwapStatus } from './utils'

const TAB_IDS = {
  BRIDGE: 'bridge',
  CLAIM: 'claim',
}

const TABS = [
  {
    id: TAB_IDS.BRIDGE,
    title: 'Bridge',
  },
  {
    id: TAB_IDS.CLAIM,
    title: 'Claim',
  },
]

export default function Bridge() {
  const [tab, setTab] = useLocalStorage<string>('BRIDGE_TAB', TABS[0].id)

  return (
    <PageWrapper>
      <CardCenteredGap gap="16px">
        <BridgeHeader />

        <AppToggler tab={tab} setTab={setTab} tabs={TABS} />

        {tab === TAB_IDS.BRIDGE && <DeBridge />}
        {tab === TAB_IDS.CLAIM && <Claim />}
      </CardCenteredGap>
    </PageWrapper>
  )
}

const InputTx = styled(AmountValueInput)`
  width: 100%;
  font-size: 16px !important;
`

const useToFromChains = () => {
  const { chainId: fromChain } = useActiveWeb3React()
  const [toChain, setToChain] = useState<number>()

  useEffect(() => {
    if (!toChain && fromChain) {
      setToChain(getTargetBridgeChain(fromChain))
    }
  }, [toChain, fromChain])

  return { fromChain, toChain, setToChain }
}

const Claim = () => {
  const [txHash, setTxHash] = useState<string>('') // 0xfef90f12b89a41d205c02c5a95bfe1bfd515e0f11784b0bce4044898210dc448

  const { fromChain, toChain, setToChain } = useToFromChains()

  const transactions = useAllDeBridgeTxs()

  const targetTx = useMemo(() => {
    return transactions.find((tx) => tx.receipt?.transactionHash?.toLowerCase() === txHash?.toLowerCase())
  }, [transactions, txHash])

  const { data } = useApiCall(
    txHash && `https://api.debridge.finance/api/Transactions/GetFullSubmissionInfo?filter=${txHash}&filterType=1`
  )

  return (
    <Column gap="16px">
      {toChain && fromChain && (
        <AppGetSwitcher color="appViolet" fromChainId={fromChain} toChainId={toChain} setToChainId={setToChain} />
      )}

      <GreyCard gap="12px">
        <InputTx
          placeholder="Enter tx hash"
          value={txHash}
          onChange={(e) => {
            setTxHash(e.target.value)
          }}
        />

        <ClaimAction
          txHash={txHash}
          from={targetTx?.bridge?.fromChainId || data?.send?.eventOriginChainId || fromChain}
          toChainId={targetTx?.bridge?.toChainId || data?.send?.chainToId || toChain}
        />
      </GreyCard>

      <Transactions />
    </Column>
  )
}

const ClaimAction = ({
  txHash,
  from,
  toChainId,
}: {
  txHash: string
  from: SupportedChainId
  toChainId: SupportedChainId
}) => {
  const { chainId: currentChainId } = useActiveWeb3React()

  const { isConfirmed, isExecuted, isSigned, claimArgs } = useSwapStatus(txHash, from, toChainId)

  const { action, txInfo, pending, isError } = useClaimDeBridgeAction(
    txHash,
    isConfirmed,
    isSigned,
    isExecuted,
    claimArgs
  )

  return (
    <Column gap="6px">
      {!txHash ? (
        <ButtonViolet disabled>
          <Dots>Enter tx hash</Dots>
        </ButtonViolet>
      ) : currentChainId !== toChainId ? (
        <ButtonViolet disabled>
          <Dots>Switch to {CHAIN_INFO[toChainId].label} to claim</Dots>
        </ButtonViolet>
      ) : !isConfirmed ? (
        <ButtonViolet disabled>
          <Dots>Confirming by validators</Dots>
        </ButtonViolet>
      ) : !isSigned ? (
        <ButtonViolet disabled>
          <Dots>Signing by validators</Dots>
        </ButtonViolet>
      ) : isExecuted ? (
        <ButtonViolet disabled>
          <Dots>Already claimed</Dots>
        </ButtonViolet>
      ) : (
        <ButtonViolet onClick={action} disabled={isError && !claimArgs}>
          <FormActionBtn pending={pending} txInfo={txInfo} labelActive="Claim" labelInProgress="Claiming" />
        </ButtonViolet>
      )}

      {!isExecuted && isSigned && isConfirmed && currentChainId === toChainId && claimArgs && (
        <TransactionInfo info={txInfo} />
      )}
    </Column>
  )
}

const Transactions = () => {
  const transactions = useAllDeBridgeTxs()

  if (!transactions.length) return null

  return (
    <Column gap="8px" maxHeight="300px" overflow="auto">
      <Typography variant="h5">Bridged/Claimable Transactions</Typography>

      {transactions.map((tx, i) => {
        if (!tx.chainId || !tx.receipt?.transactionHash || !tx.bridge?.toChainId) return null

        const explorerLink = getExplorerLink(
          tx.bridge.fromChainId,
          tx.receipt?.transactionHash,
          ExplorerDataType.TRANSACTION
        )
        return (
          <GreyCard key={tx.hash} gap="6px">
            <a href={explorerLink} target="_blank" rel="noreferrer">
              <TYPE.blue>
                Created at: {fullDate(tx.addedTime)} from {CHAIN_INFO[tx.bridge.fromChainId].label} to{' '}
                {CHAIN_INFO[tx.bridge.toChainId].label}
              </TYPE.blue>
            </a>

            <ClaimAction
              txHash={tx.receipt?.transactionHash}
              from={tx.bridge.fromChainId}
              toChainId={tx.bridge?.toChainId}
            />
          </GreyCard>
        )
      })}
    </Column>
  )
}

const DeBridge = () => {
  const addressToken = useBridgableWXfiAddress()

  const { fromChain, toChain, setToChain } = useToFromChains()

  const balance = useTokenBalance(addressToken)

  const [amount, setAmount] = useState<BigNumber | undefined>(undefined)

  const noValue = !amount || amount.isZero()

  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const { pending, action, isError, txInfo, calledWallet } = useDeBridgeAction(amount, toChain, setPendingTx)

  const rightToken = useMemo(() => {
    return {
      address: addressToken,
      symbol: 'wXFI' as TokenSymbol,
    }
  }, [addressToken])

  if (pendingTx) {
    return (
      <PendingBridgeView
        onBack={() => setPendingTx('')}
        amount={amount}
        color="appViolet"
        hash={pendingTx}
        token={TokenSymbol.xfi}
        txInfo={txInfo}
      />
    )
  }

  return (
    <>
      {fromChain && toChain && (
        <AppGetSwitcher
          color="appViolet"
          fromChainId={fromChain}
          toChainId={toChain}
          setToChainId={setToChain}
          onUserInput={setAmount}
        />
      )}

      <AmountInputWithBalance
        label="Heading"
        useBalanceAsMax
        balance={balance}
        inputValue={amount}
        setInputValue={(v) => v && setAmount(v)}
        bgColor="main15"
        rightToken={rightToken}
      />

      <WarningBlock text="Bridge is working on next chains: BSC, Polygon, Arbitrum, Optimism. Please switch to supported chains" />

      <TransactionInfo info={txInfo} />

      <ConfirmInWalletBlock calledWallet={calledWallet}>
        {noValue ? (
          <ButtonViolet disabled={noValue}>Enter an amount</ButtonViolet>
        ) : (
          <ApproveCheckerDeBridge token={addressToken} border={amount}>
            <ButtonViolet onClick={action} disabled={isError || !addressToken}>
              <FormActionBtn pending={pending} txInfo={txInfo} labelActive="Bridge" labelInProgress="Bridging" />
            </ButtonViolet>
          </ApproveCheckerDeBridge>
        )}
      </ConfirmInWalletBlock>
    </>
  )
}
