import { InputAdornment, InputProps } from '@mui/material'
import walletSvg from 'assets/icons/wallet.svg'
import { RightTokenSelector } from 'components/blocks/AmountInput/RightTokenSelector'
import {
  AmountBalanceRow,
  AmountValueInput,
  BalanceValue,
  InputContainer,
  MaxButton,
  WalletIcon,
} from 'components/blocks/AmountInput/styles'
import { IAmountInput, IAmountWithMax, useAmountInput } from 'components/blocks/AmountInput/useAmountInput'
import { GreyCard } from 'components/Card'
import { Box } from 'components/MUI'
import { RowBetween } from 'components/Row'
import { BigNumber } from 'ethers'
import { useTokenBalance, useTokenDecimals } from 'hooks/base/token'
import { useCallback, useMemo, useState } from 'react'
import { useNativeCurrencyBalance } from 'state/wallet/hooks'
import { TYPE } from 'theme/theme'
import { fromWei } from 'utils/fromWei'
import { getBigNumberValue } from 'utils/getBigNumberValue'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

import { TokenSymbol } from './useAppCoins'

/**
 * Amount Input
 * @constructor
 */
const AmountInput = (props: IAmountInput) => {
  const {
    onMaxClicked,
    hideMax = false,
    max,
    placeholder,
    rightTokenOptions,
    onChangeRightToken,
    balance,
    decimals,
    label,
    ...rest
  } = props
  const { value, onChange, rightToken } = useAmountInput(props)

  const { walletIcon, showBalanceRow = true, validateBalanceExceedsZero = true } = props

  const maxDisabled = hideMax || !max || max.isZero()

  const isInvalid = validateBalanceExceedsZero && max && props.inputValue && props.inputValue.gt(max)

  const inputProps = useMemo(() => {
    const result: InputProps = {}

    if (rightToken) {
      result.endAdornment = (
        <InputAdornment position="end">
          {rightToken ? (
            <RightTokenSelector
              value={rightToken}
              options={rightTokenOptions}
              onChangeRightToken={onChangeRightToken}
            />
          ) : (
            <Box width={4} />
          )}
        </InputAdornment>
      )
    }
    return result
  }, [rightToken, rightTokenOptions, onChangeRightToken])

  const [focused, setFocused] = useState(false)

  const handleOnFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const handleOnBlur = useCallback(() => {
    setFocused(false)
  }, [])

  const hasBalanceValue = max || balance

  const onChangeWrapper = useCallback(
    (event: any) => {
      focused && !rest.disabled ? onChange(event) : undefined
    },
    [focused, onChange, rest.disabled]
  )

  return (
    <GreyCard gap="16px" isInvalid={isInvalid}>
      {label && (
        <TYPE.body fontWeight={400} color="dark40">
          {label}
        </TYPE.body>
      )}
      <InputContainer>
        <AmountValueInput
          error={isInvalid}
          InputProps={inputProps}
          inputProps={{
            // universal input options
            inputMode: 'decimal',
            autoComplete: 'off',
            autoCorrect: 'off',
            // text-specific options
            type: 'number',
            pattern: '^[0-9]*[.,]?[0-9]*$',
            placeholder: placeholder || '0.0',
            minLength: 1,
            maxLength: 79,
            spellCheck: 'false',
            max,
            value,
          }}
          value={value}
          onChange={onChangeWrapper}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          {...rest}
        />

        {showBalanceRow && hasBalanceValue ? (
          <RowBetween marginTop="16px">
            {isInvalid && (
              <BalanceValue fontWeight={600} color="red">
                Exceeds balance
              </BalanceValue>
            )}

            <AmountBalanceRow alignItems="center" flex="1" justify="flex-end">
              {onMaxClicked && !maxDisabled && <MaxButton onClick={onMaxClicked}>+max</MaxButton>}

              <WalletIcon src={walletIcon || walletSvg} />

              <BalanceValue fontWeight={400}>{formatDecimal(max || balance || ZERO, 2, decimals)}</BalanceValue>
            </AmountBalanceRow>
          </RowBetween>
        ) : null}
      </InputContainer>
    </GreyCard>
  )
}

export const AmountInputWithMax = ({
  inputValue,
  setInputValue,
  decimals = 18,
  useBalanceAsMax,
  ...rest
}: IAmountWithMax) => {
  const inputAsNumber = useMemo(() => +fromWei(inputValue || ZERO, decimals) || undefined, [inputValue, decimals])

  const onInputHandler = useCallback(
    (val: any) => {
      setInputValue && setInputValue(getBigNumberValue(val ? +val : 0, BigNumber.from(10).pow(decimals)))
    },
    [decimals, setInputValue]
  )

  const maxValue = rest.max || rest.balance

  const onMaxHandler = useCallback(() => {
    setInputValue && setInputValue(maxValue)
  }, [maxValue, setInputValue])

  return (
    <AmountInput
      value={inputAsNumber}
      onUserInput={onInputHandler}
      max={maxValue}
      onMaxClicked={onMaxHandler}
      decimals={decimals}
      inputValue={inputValue}
      {...rest}
    />
  )
}

type WithoutMaxAndBalance = Omit<IAmountWithMax, 'decimals' | 'max'>

export const AmountInputWithBalance = (props: WithoutMaxAndBalance) => {
  if (props.rightToken?.address === TokenSymbol.xfi) return <AmountInputNativeToken {...props} />
  else return <AmountInputPlainToken {...props} />
}

const AmountInputNativeToken = (props: WithoutMaxAndBalance) => {
  const nativeBalance = useNativeCurrencyBalance()
  return <AmountInputWithMax {...props} max={nativeBalance} decimals={18} />
}

const AmountInputPlainToken = (props: WithoutMaxAndBalance) => {
  const balance = useTokenBalance(props.rightToken?.address)
  const decimals = useTokenDecimals(props.rightToken?.address)

  return <AmountInputWithMax {...props} max={balance} decimals={decimals} />
}
