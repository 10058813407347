import * as React from 'react'
import { useTheme } from 'styled-components'
import { ThemeColors } from 'theme/styled'

function Icon(props: React.SVGProps<SVGSVGElement> & { color?: ThemeColors }, svgRef?: React.Ref<SVGSVGElement>) {
  const theme = useTheme()

  const color = props.color && (theme as any)[props.color] ? theme[props.color] : theme.fuchsia

  return (
    <svg width="938" height="938" viewBox="0 0 938 938" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="469" cy="469" r="469" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M469.001 308.635L603.635 174.002L764 174V334.367L629.367 469L764 603.633V764.001L603.634 763.999L469.001 629.366L334.368 763.999H174L174.002 603.633L308.636 469L174.002 334.367L174 174.001L334.368 174.002L469.001 308.635ZM437.167 340.469L315.72 219.022L219.02 219.021L219.021 315.719L340.469 437.167L437.167 340.469ZM372.303 469L469.001 372.302L565.699 469L469.001 565.698L372.303 469ZM340.469 500.834L219.022 622.281L219.02 718.98L315.72 718.979L437.167 597.532L340.469 500.834ZM500.835 597.532L622.282 718.979L718.98 718.981V622.281L597.533 500.834L500.835 597.532ZM597.533 437.167L500.835 340.469L622.283 219.022L718.981 219.02L718.98 315.719L597.533 437.167Z"
        fill="#0CC2FF"
      />
    </svg>
  )
}

const ForwardRef = React.forwardRef(Icon)
const MemoForwardRef = React.memo(ForwardRef)

export default MemoForwardRef
