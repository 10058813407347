import bridgeSvg from 'assets/images/menu/bridge.svg'
import escrowSvg from 'assets/images/menu/escrow.svg'
import getSvg from 'assets/images/menu/get.svg'
import rewardsSvg from 'assets/images/menu/rewards.svg'
import stakingSvg from 'assets/images/menu/staking.svg'
import swapSvg from 'assets/images/menu/swap.svg'
import xUsdSvg from 'assets/images/menu/xusd.svg'
import { Paths } from 'constants/paths'
import { useMemo } from 'react'

interface IMenuItem {
  href: string
  src: string
  label: string
  disabled?: boolean
}

interface IMenu {
  get: IMenuItem
  swap: IMenuItem
  bridge: IMenuItem
  staking: IMenuItem
  rewards: IMenuItem
  escrow: IMenuItem
  xusd: IMenuItem
}

export const useMenu = (): IMenu => {
  return useMemo(() => {
    return {
      get: { href: Paths.GET, src: getSvg, label: 'Get' },
      swap: { href: Paths.SWAP, src: swapSvg, label: 'Swap' },
      bridge: { href: Paths.BRIDGE, src: bridgeSvg, label: 'Bridge', disabled: true },
      staking: { href: Paths.STAKING, src: stakingSvg, label: 'Staking' },

      rewards: { href: Paths.REWARDS, src: rewardsSvg, label: 'Rewards' },
      escrow: { href: Paths.ESCROW, src: escrowSvg, label: 'Escrow' },
      xusd: { href: Paths.xUSD, src: xUsdSvg, label: 'xUSD' },
    }
  }, [])
}

export const useMenuArray = () => {
  const menu = useMenu()

  return useMemo(() => {
    return Object.values(menu)
  }, [menu])
}
