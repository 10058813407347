import { TransactionResponse } from '@ethersproject/providers'
import { TxTemplateTypes } from 'constants/transactions'
import { BigNumber, Contract } from 'ethers'
import { useTxTemplate } from 'hooks/base/tx-template'
import { useCallback } from 'react'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

export const useClaimRewards = (
  contract: Contract | null,
  value: BigNumber = ZERO,
  setPendingTx: (v: string) => void
) => {
  const dataFunc = useCallback(async () => {
    return await contract?.populateTransaction.getReward()
  }, [contract])

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx(tx.hash)
    },
    [setPendingTx]
  )

  return useTxTemplate(
    TxTemplateTypes.Claimed,
    `$claim_staking_rewards`,
    `Claimed ${formatDecimal(value)} staking rewards`,
    dataFunc,
    setTx
  )
}
